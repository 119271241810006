import React, { useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from 'firebase/auth';
import { FieldValues, useForm, FormProvider } from 'react-hook-form';

import { EMAIL_VALIDATION_REG_EXP } from 'wwg-utilities/dist/validation';
import { AlertState, AuthType, LoginType } from '../../../../lib/enums';
import { useAuthType } from '../../../../lib/providers/authTypeState';
import { isWhereWeGo } from '../../../../lib/config/project/project';
import getUserByEmailRequest from '../../../../lib/api/getUserByEmailRequest';
import auth from '../../../../lib/config/firebase';
import Alert from '../../../shared/Alert';
import TextInput from '../../../shared/TextInput';
import SpinnerIcon from '../../../icons/Spinner';
import Google from '../../../icons/Google';

type EmailLoginProps = {
  setLoginType: React.Dispatch<React.SetStateAction<LoginType>>;
};

const EmailLogInForm: React.FC<EmailLoginProps> = ({ setLoginType }) => {
  const methods = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setIsSubmissionError] = useState('');
  const { authType, setAuthType } = useAuthType();
  const provider = new GoogleAuthProvider();

  const handleEmailLogin = async (data: FieldValues) => {
    setIsSubmissionError('');
    setIsSubmitting(true);

    const { email, password } = data;
    if (isWhereWeGo) {
      const existingUser = await getUserByEmailRequest(email).catch(
        console.error,
      );
      if (existingUser) {
        setAuthType(AuthType.RecoverOldAccount);
        return;
      }
    }
    signInWithEmailAndPassword(auth, email, password)
      .catch(async (error) => {
        setIsSubmissionError(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleGoogleSignIn = () => {
    signInWithRedirect(auth, provider).catch((err) => {
      console.log(err.message);
    });
  };

  return (
    <div className="p-6">
      <FormProvider {...methods}>
        <Alert message={submissionError} type={AlertState.Error} />
        {authType == AuthType.Login && (
          <h2 className="text-xl font-bold font-heading text-purple">
            Welcome back!
          </h2>
        )}
        <form
          className="flex flex-col my-4 justify-evenly align-stretch grow"
          onSubmit={methods.handleSubmit(handleEmailLogin)}
        >
          <TextInput
            methods={methods}
            name="email"
            label="Enter your email address"
            id="emailAddressInput"
            type="email"
            required={true}
            pattern={{
              value: EMAIL_VALIDATION_REG_EXP,
              message: 'Please provide a valid email',
            }}
          />
          <TextInput
            methods={methods}
            name="password"
            label="Enter your email password"
            id="passwordInput"
            type="password"
            required={true}
          />
          <div data-testid="login-submit">
            <button className="flex disabled:cursor-not-allowed my-3 font-heading font-bold py-2 px-4 mx-auto rounded-full border-2 hover:text-white hover:bg-green border-green text-green bg-white min-w-[235px] flex items-center justify-center">
              {isSubmitting ? <SpinnerIcon className="w-6 h-6" /> : ''}
              Log in
            </button>
          </div>
          <hr />
          <div className="mt-4">
            <button
              className="flex disabled:cursor-not-allowed my-3 font-heading font-bold py-2 px-4 mx-auto rounded-full border-2  hover:text-white hover:bg-purple border-purple text-purple bg-white min-w-[235px] flex items-center justify-center"
              onClick={() => {
                setLoginType(LoginType.Phone);
              }}
            >
              Log in with Phone
            </button>
          </div>
        </form>
        <button
          className="flex disabled:cursor-not-allowed my-3 font-heading font-bold py-2 px-4 mx-auto rounded-full border-2  bg-white min-w-[235px] flex items-center justify-center hover:text-white hover:fill-white hover:bg-primary-dark border-primary-dark text-primary-dark fill-primary-dark"
          onClick={handleGoogleSignIn}
        >
          <Google className="w-5 h-5" /> &nbsp;Continue with Google
        </button>
        <div className="flex flex-col text-center font-heading font-semi-bold ">
          <p
            onClick={() => {
              setAuthType(AuthType.ResetPassword);
            }}
            className="my-1 cursor-pointer hover:text-gold"
            tabIndex={1}
          >
            Forgot your password?
          </p>
          <p
            tabIndex={1}
            className="my-1 cursor-pointer hover:text-gold"
            onClick={() => {
              setAuthType(AuthType.Register);
            }}
          >
            Need an account?
          </p>
        </div>
      </FormProvider>
    </div>
  );
};

export default EmailLogInForm;
