import { NextPage } from 'next';
import GNOHeader from '../components/wherewego/Header';
import ProjectLoginForm from '../lib/config/project/components/ProjectLoginForm';
import GNOFooter from '../components/wherewego/Footer';

const LoginPage: NextPage = () => {
  return (
    <>
      <GNOHeader />
      <div className="border-b-1 border-b-solid border-b-gray"></div>
      <div className="p-4 mx-auto sm:p-8 md:px-2 md:py-4 md:w-2/4 lg:py-14">
        <ProjectLoginForm />
      </div>
      <GNOFooter />
    </>
  );
};

export default LoginPage;
