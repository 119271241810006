import React, { useState } from 'react';
import { LoginType } from '../../../../lib/enums';
import EmailLogInForm from '../EmailLoginForm';
import PhoneAuthForm from '../PhoneAuthForm';

const LoginForm: React.FC<{}> = ({}) => {
  const [loginType, setLoginType] = useState(LoginType.Phone);

  if (loginType == LoginType.Phone) {
    return <PhoneAuthForm setLoginType={setLoginType} />;
  }
  if (loginType === LoginType.Email) {
    return <EmailLogInForm setLoginType={setLoginType} />;
  }
  return <></>;
};

export default LoginForm;
