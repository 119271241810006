import LoginForm from '../../../../components/wherewego/auth/LoginForm';

interface ProjectLoginFormProps {
  closeModal?: (value: boolean) => false
}

const ProjectLoginForm: React.FC<ProjectLoginFormProps> = () => {
 
    return <LoginForm />
  return <section></section>;
};

export default ProjectLoginForm;
